import { createContext, FC, ReactNode, useContext, useEffect, useState } from 'react'
import { log } from '../utils/log'

interface UserData {
  name: string | null
  email: string | null
  lmsUserId: string | null
  roles: string[] | null
}

const useContextHook = () => {
  const [launchUrl, setLaunchUrl] = useState('')
  const [assistantId, setAssistantId] = useState('')
  const [courseId, setCourseId] = useState<string>('')
  const [sectionId, setSectionId] = useState('')
  const [userData, setUserData] = useState<UserData>({
    name: null,
    email: null,
    lmsUserId: null,
    roles: null,
  })
  const [conversationId, setConversationId] = useState('')
  const [fullscreen, setFullscreen] = useState(false)
  const [inputFocused, setInputFocused] = useState(false)

  // useEffect to log when launchUrl changes
  useEffect(() => {
    log('[ConversationProvider.tsx] [useEffect] [launchUrl] launchUrl changed:', launchUrl)
  }, [launchUrl])

  // We previously used userData.ready to determine if we should do the "normal" (injector) sign-in.
  // However, with temporary-token-based sign-in, we still need to get the user data from the back-end,
  // so we can't rely on the presence of userData to know whether to do a normal sign-in.
  // Instead, we use an additional state variable to track the sign-in method.
  const [shouldDoSignIn, setShouldDoSignIn] = useState<'injector' | 'lti' | null>(null)

  useEffect(() => {
    try {
      const local = window.sessionStorage.getItem('conversation')
      if (assistantId) {
        log('[ConversationProvider.tsx] [useEffect] [assistantId] Setting conversationId from session storage')
        const stored = local ? JSON.parse(local)?.[assistantId] : null

        // Handle both new and old storage format
        const storedConversationId = typeof stored === 'string' ? stored : stored?.conversationId
        const storedCourseId = typeof stored === 'string' ? null : stored?.courseId

        // Only set the conversation if:
        // 1. We have a stored conversation ID AND
        // 2. Either:
        //    - Both course IDs are null (not in course context)
        //    - OR both course IDs are set and match
        log(
          '[ConversationProvider.tsx] [useEffect] [assistantId] storedConversationId:',
          storedConversationId,
          ' - courseId:',
          courseId,
          ' - storedCourseId:',
          storedCourseId,
        )
        if (
          storedConversationId &&
          ((courseId === null && storedCourseId === null) ||
            (courseId && storedCourseId && courseId === storedCourseId))
        ) {
          setConversationId(storedConversationId)
          log(
            '[ConversationProvider.tsx] [useEffect] [assistantId] Set conversationId from session storage:',
            storedConversationId,
          )
        } else {
          log('[ConversationProvider.tsx] [useEffect] [assistantId] Course ID mismatch or invalid state:', {
            currentCourseId: courseId,
            storedCourseId,
          })
        }
      }
    } catch (e: any) {}
  }, [assistantId, courseId])

  const handleConversationId = (val: string) => {
    log('[ConversationProvider.tsx] [handleConversationId] Setting conversationId to:', val)
    setConversationId(val)

    try {
      const currentConversations = window.sessionStorage.getItem('conversation')
      const parsed = currentConversations ? JSON.parse(currentConversations) : {}

      // Store both conversation ID and course ID in new format
      const updatedConversations = {
        ...parsed,
        [assistantId]: val ? { conversationId: val, courseId } : val,
      }
      window.sessionStorage.setItem('conversation', JSON.stringify(updatedConversations))
      log(
        '[ConversationProvider.tsx] [handleConversationId] Updated conversation in session storage:',
        updatedConversations,
      )
    } catch (e: any) {}
  }

  return {
    launchUrl,
    setLaunchUrl,
    assistantId,
    setAssistantId,
    courseId,
    setCourseId,
    sectionId,
    setSectionId,
    userData,
    setUserData,
    conversationId,
    setConversationId: handleConversationId,
    fullscreen,
    setFullscreen,
    inputFocused,
    setInputFocused,
    shouldDoSignIn,
    setShouldDoSignIn,
  }
}

type StorageHook = ReturnType<typeof useContextHook>

const ConversationContext = createContext<StorageHook>({} as any)

type Props = {
  children?: ReactNode
}

const ConversationProvider: FC<Props> = ({ children }) => {
  const props = useContextHook()
  return <ConversationContext.Provider value={props}>{children}</ConversationContext.Provider>
}

export const useConversationContext = () => useContext(ConversationContext)

export default ConversationProvider
